import React from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { setTheme } from "store/action";

import Header from "components/layout/Header";
import Footer from "components/layout/Footer";

const LayoutDefault = ({ children }) => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.darkMode);

  const toggleDarkMode = (checked) => {
    dispatch(setTheme(checked));
  };

  return (
    <>
      <Header
        navPosition="right"
        // className="reveal-from-bottom"
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
      />
      <main
        className={classNames(
          "site-content",
          isDarkMode ? "site-content-dark" : "site-content-light"
        )}
      >
        {children}
      </main>
      <Footer />
    </>
  );
};

export default LayoutDefault;
