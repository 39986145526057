import React, { useRef, useEffect } from "react";
import { useLocation, Switch } from "react-router-dom";
import AppRoute from "utils/AppRoute";
import ScrollReveal from "utils/ScrollReveal";
import ReactGA from "react-ga";
// import axios from "axios";

// Layouts
import LayoutDefault from "layouts/LayoutDefault";

// Views
// import Home from "views/Home";
// import Product from "views/Product";
import PdfComproViewer from "views/Compro";
// import Test from "components/sections/Test";

import "mapbox-gl/dist/mapbox-gl.css";

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    // (async function () {
    //   try {
    //     const test = await axios({
    //       url: `${process.env.REACT_APP_API_URL_BASE_API}/test`,
    //       method: "GET",
    //     });
    //     console.log(test, " --> test");
    //   } catch (err) {
    //     console.log(err, " --> error test");
    //   }
    // })();
    const page = location.pathname;
    document.body.classList.add("is-loaded");
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          {/* <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute
            exact
            path="/product"
            component={Product}
            layout={LayoutDefault}
          /> */}
          <AppRoute
            exact
            path="/"
            component={PdfComproViewer}
            layout={LayoutDefault}
          />
        </Switch>
      )}
    />
  );
};

export default App;
