import { legacy_createStore as createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

let initalState = {
  darkMode: false,
};

function reducer(state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case "SET_THEME":
      return { ...state, darkMode: payload };
    default:
      return state;
  }
}

let store = createStore(reducer, applyMiddleware(thunk));

export default store;
