import React, { useState } from "react";
// import { Document, Page } from "react-pdf";
import ComproPDF from "assets/CP206.pdf";
// import ButtonGroup from "components/elements/ButtonGroup";
// import Button from "components/elements/Button";
// import { pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import "./pdfViewer.scss";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
function PDFViewer() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <>
      <div className="container-xs mt-32"></div>
      <Document
        file={ComproPDF}
        options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <span className="testimonial-item-link">
          <a href="https://forms.office.com/r/aLJ1ac35E0" target="_blank">
            Please Submit This Form
          </a>
        </span>
        <ButtonGroup>
          <Button
            tag="a"
            color="primary"
            wideMobile
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            Previous
          </Button>
          <p style={{ textAlign: "center" }}>
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </p>
          <Button
            tag="a"
            color="secondary"
            wideMobile
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Next
          </Button>
        </ButtonGroup>
      </div> */}
    </>
  );
}

export default PDFViewer;
