import React from "react";

import PDFViewer from "components/sections/partials/PDFViewer";

const PdfComproViewer = () => {
  return (
    <>
      <PDFViewer />
    </>
  );
};

export default PdfComproViewer;
